
$main-colour: #0d9d93; //TODO: factor our colour definitions

h1 { font-size: 22px;}

.hunt-que-img{
  // height: 250px;
  //width: 100px;
  height: auto;
}

.hunt-ans-btw{
  //  min-width: 250px !important;
  width: 100%;
  background-color: $main-colour !important;
  border: none !important;
  padding: 6px 20px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px !important;
  color: #ecf0f1 !important;
  //white-space: nowrap;
  text-transform: none !important;
}

 .hunt-title {
  padding-top: 24px;
//  padding-bottom: 16px;
}

 .actionButton img {
   width: 25px;
   height: 25px;
 }

 .MuiButton-root {
   min-width: 25px !important;
 }

 .questionBanner {
   height: 36px;
   background-color: #E9E8DB;

   h4 {
     font-size: 12px;
     font-family: 'Sniglet', cursive;
   }
 }

 .answerImageContainer {
    text-align: center;
    padding-top: 32px;
    img.answerImage {  width: 98px; display: inline-block; }
  }

  .answer {
    margin-top: 30px;

    h1 { font-size: 16px; font-family: 'Roboto', sans-serif;  }
  }

  .infoText {
    font-size: 16px; font-family: 'Roboto', sans-serif;
  }

 .answerDetailsPopup {
   position: fixed;
   bottom: 0;
   left: 0;
   right: 0;
   background: white;
   font-size: 16px;
   border-radius: 54px 54px 0 0;
   border: 2px solid #0d9d93;


   .popupContent {
     padding: 90px 50px 25px 50px;
   }
 }

@media (min-width:280px) and (max-width:768px) {
  .hunt-que-img {
  //  height:150px !important
  }
  .hunt-ans-btw{
    width: 100% !important;
    background-color: $main-colour !important;
    border: none !important;
    padding: 6px 20px !important;
    font-size: 1.6rem !important;
    color: #ecf0f1 !important;
   // white-space: nowrap;
    text-transform: none !important;
  }

  .header-logo img {
    width: 50px;
    margin: -50px -10px;
  }
  .header-logo h1 {
    //font-size: 1rem;  //ZH
//    padding-left: 1rem;
  }
  .header-text a {
   // font-size: 1rem;  //ZH
 //  padding: 10px 7px; ZH
  }
  .header-text {
   // margin-top: 6px;
  }
}

.location_clue_text {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  margin-top: 23px;
  margin-bottom: 12px;
}

.rewardButtons {
  background: $main-colour !important;
  color: white !important;
  font-size: 16px !important;
  font-family: 'Roboto', sans-serif;

  &:hover {
    background: $main-colour !important;
    color: white !important;
  }
}

.rewardMessageContainer {
  background: #FF6F61;
  clip-path: polygon(100% 0, 85% 50%, 100% 100%, 0 100%, 0 0);
  position: relative;
  min-height: 85px;
  color: white;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}

.rewardMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
}

.answerDetailImage {
  width: 64px;
}

.detailedQA {
  font-size: 17px;
}
