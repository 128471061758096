body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.play-btn {
  background-color: transparent !important;
  color: #FF6F61 !important;
  border: none !important;
  margin-left: 5px !important;
  vertical-align: middle;
  align-self: center;
}
