$main-colour: #0d9d93;
$highlight-colour1: #de4d44;
$highlight-colour2: #ffae15;
$header-background-color: #E9E8DB;
$header-selected-color: #707070;
$text-color: #303030;
$trails-text-color: #707070;
$location-text-color: #c3c0c0;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5% !important; //1 rem is 10px; 10/16 = 62.5% from default browser font size
  height: 100%;
}

body {
  box-sizing: border-box;
  height: 100%;
  min-height: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}
* {
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

h1 {
  font-family: 'Sniglet', cursive;
  font-size: 17px;
}

h2 {
  font-family: 'Sniglet', cursive;
  font-size: 20px;
}

p:last-child {
  margin-top: 0;
}
.custom-flex {
  display: flex;
  align-items: center;
}
header {
  width: 100%;
  background-color: $header-background-color;

align-items: center;
justify-items: center;
height:75px;
//padding: 20px 0px 15px 0px;
}
.header-logo {
  @extend .custom-flex;
  font-size: 1.5rem;  //ZH added
  padding: 0px;
  margin: 0px;
  align-items: center;
  justify-items: center;
  img{
 // width: 70px;
 // height: 70px;
  width: 85px;
  border-radius: 50%;
  margin: -30px -10px;
}
  h1 {
    color: $text-color;
 //   font-size: 2rem; ZH
    margin-bottom: 0;
    font-weight: bold;
    padding-left: 2rem;
  }
}
.header-text {
    font-size: 1.5rem;  //ZH added
    margin:0px;  //remove Nav margin
  ul {
    @extend .custom-flex;
    list-style: none;
  }
  a {
    color: $text-color;
  // font-size: 1.8rem;  //ZH
    padding: 10px 30px;
    &:hover {
      text-decoration: none;
      color: $header-selected-color;
    }
  }
}
a {
  color: #ffffff;
}
.header-right {
  @extend .custom-flex;
  justify-content: space-between;
  align-items: center;
 // padding-top: 1rem; ZH
  padding-bottom: 1rem;
  //padding-left: 4rem; ZH
   padding-right: 4rem;
  ul {
    margin:0px;
    padding: 0px;
  }
}

.box {
  height: 500px;
  margin-top: 20rem;
}
.left-right-space {
  padding-left: 7rem;
  padding-right: 7rem;
}
.phone-img {
  display: flex;
  justify-content: flex-end;
}
.trails-one {
  border-right: 1px solid $trails-text-color;
  padding-right: 5rem;
  h1 {
    color: $trails-text-color;
    font-size: 5rem;
    margin-bottom: 0;
  }
}
.trails-one-text {
  h1 {
    color: $trails-text-color;
    font-size: 5rem;
    margin-bottom: 0;
  }
}
.trails-flex {
  @extend .custom-flex;
  justify-content: space-between;
  padding-top: 6rem;
}
.phone-img {
  img {
    width: 374px;
    height: 674px;
    margin-top: 8rem;
  }
}
.space-top {
  padding-top: 15rem;
}
.box-content {
  padding: 4rem;
  border: 3px solid $highlight-colour2;
}
.location-text {
  h1 {
    color: $trails-text-color;
    font-size: 3rem;
    font-weight: 500;
    text-decoration: underline;
  }
  p {
    color: $location-text-color;
    font-size: 20px;
    margin-top: 0.5rem;
  }
}
.location-text-top-space {
  padding-top: 2rem;
}
.answer-flex {
  display: flex;
  p {
    color: $location-text-color;
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 0;
  }
}
.file-flex {
  @extend .custom-flex;
  justify-content: space-between;
  padding-top: 2rem;
  .file-box {
    flex-basis: 22%;
    height: 13rem;
    border: 1px solid $trails-text-color;
  }
}
.file-text-video {
  color: $location-text-color;
  font-size: 2rem;
  position: relative;
  top: 25%;
  text-align: center;
}
.file-text {
  h1 {
    color: $location-text-color;
    font-size: 2rem;
    text-align: center;
    margin-top: 1.5rem;
  }
}
.picture-text {
  flex-basis: 25%;
}
.file-box-bottom-text {
  padding-top: 1rem;
  display: flex;
  h1 {
    color: $location-text-color;
    font-size: 2rem;
    text-align: center;
  }
}
.squre-space-top {
  padding-top: 3rem;
}
.squre {
  width: 89px;
  height: 89px;
  border: 5px solid $header-background-color;
  border-radius: 50%;
  text-align: center;
  line-height: 105px;
}
.fa-chevron-up:before {
  content: "\f077";
  @extend .icon-style;
}
.squre-flex {
  @extend .custom-flex;
  h1 {
    color: $header-background-color;
    font-size: 3rem;
    margin-bottom: 0;
    padding-left: 1rem;
  }
}
.icon-style {
  font-size: 5rem;
  color: $header-background-color;
}
.fa-chevron-down:before {
  content: "\f078";
  @extend .icon-style;
}
.fa-pencil-alt:before {
  content: "\f303";
  @extend .icon-style;
}
.fa-trash-alt:before {
  content: "\f2ed";
  @extend .icon-style;
}
@media (min-width: 900px) and (max-width: 1440px) {
  .left-right-space {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .custom-one {
    width: 205px !important;
  }
  .header-text a {
    padding: 10px 15px;
  }
  .edjucation-new {
      padding: 5rem !important;
  }
  .edjucation-new .edjucation-plus {
      line-height: 25rem;
  }
  .edjucation-build h1 {
      padding-top: 1rem !important;
  }
  .edjucation-new .edjucation-plus h1 {
      margin-top: 7rem !important;
  }
  .squre-flex h1 {
    font-size: 1.9rem;
  }
}
.banner {
  background-color: #ecf0f1;
  padding-bottom: 5rem;
  padding-top: 7rem;
}
.your-text {
  h1 {
    color: $trails-text-color;
    text-align: center;
    font-size: 5rem;
    margin-bottom: 0;
  }
}
.left-right-space-new {
  padding-left: 5.5rem;
  padding-right: 5.5rem;
}
.build-box {
  width: 100%;
  height: 43rem;
  background: #ffffff;
  margin-top: 3rem;
  .video-text {
    h1 {
      color: #707070;
      font-size: 4rem;
      line-height: 38rem;
      text-align: center;
    }
  }
}
.center-box {
  display: flex;
  justify-content: center;
}
.search-deign {
  width: 100%;
  width: 554px;
  height: 6rem;
  outline: none;
  border: 1px solid $header-background-color;
  color:black;
  font-size: 2rem;
  border-radius: 6px;
  padding-left: 3rem;
  padding-right: 3rem;
}
.search-button {
  // width: 554px;
  height: 6rem;
  outline: none;
  border: 3px solid $header-background-color;
  background-color: $header-background-color;
  border-radius: 10%;
  font-size: 3rem;
  margin-left: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.folder-style {
  color: $trails-text-color;
  font-size: 3rem;
  padding-left: 0.5rem;
}
.file {
  @extend .custom-flex;
  padding-left: 7rem;
}
.search-input {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  padding-left: 7rem;
  padding-right: 7rem;
}
.edjucation-banner {
  background: #ecf0f1;
  padding-bottom: 5rem;
}
.edjucation-new {
  background-color: #ffffff;
  padding: 10rem;
  text-align: center;
  height: 40vh;
  .edjucation-plus {
    line-height: 22rem;
    h1 {
      color: #707070;
      font-size: 5rem;
      margin-top: 5rem;
    }
  }
  .fa-plus:before {
    content: "\f067";
    font-size: 8rem;
    color: $header-background-color;
  }
}

.edjucation-build {
  background: $highlight-colour2;
  min-height: 10vh;
}
.space-top-box {
    padding-top: 2rem;
}
.edjucation-build {
  h1 {
    color: #ffffff;
    font-size: 4rem;
    text-align: center;
    padding-top: 2rem;
  }
}
.main-edjucation-box {
    margin-bottom: 4rem;
}
.trails-flex-new {
    padding-top: 6rem;
    @extend .custom-flex;
}
.new-trail-style {
    padding-left: 4rem;
}
.new-text {
    h1 {
        color: $header-background-color;
        font-size: 4rem;
    }

}
.left-tourism-text {
    padding-top: 7rem;
}
.trail-name {
    h1 {
        font-size: 4rem;
        color: #707070;
        font-weight: 400;
        text-decoration: underline;
        margin-bottom: 1.8rem;
    }
}
.trail-comapny-logo-top-space {
    padding-top: 10rem;
}
.what-box {
    height: 260px !important;
    width: 100%;
    outline: none;
    border: 1px solid #707070;
    font-size: 3rem;
    color: #C3C0C0;
    padding-left: 2rem;
    p {
        margin-top: 2rem;
    }

}
.what-box-one {
    height: 155px !important;
    width: 100%;
    outline: none;
    border: 1px solid #707070;
    font-size: 3rem;
    color: #C3C0C0;
    padding-left: 2rem;
    p {
        margin-top: 2rem;
    }

}
.what-box-two {

    width: 100%;
    font-size: 3rem;
    color: #C3C0C0;
    p {
        margin-top: 2rem;
    }

}
.trail-search-design {
    width: 100%;
    height: 68px;
    outline: none;
    border: 1px solid #707070;
    font-size: 3rem;
    color: #C3C0C0;
    padding-left: 2rem;

}
.front-page {
    width: 226px;
    height: 200px;
    border: 1px solid $trails-text-color;
    text-align: center;
    padding: 5rem;
    margin-top: 5rem;
    line-height: 15rem;
    .fa-plus:before {
      content: "\f067";
      font-size: 50px;
      color: $header-background-color;
  }
}
.custom-one {
  width: 226px;
  height: 226px;
  border-radius: 4px;
  background-color: $header-background-color;
  margin-bottom: 4rem;
  padding:20px;
  text-align: center;
  p {
    color: #ffffff;
    font-size: 2.7rem;
  }
}
.custom-box {
 @extend .custom-flex;
 justify-content: space-between;
 flex-wrap: wrap;
 padding-top: 5rem;
}
.border-custom {
  border-top: 1px solid #ffffff;
}
.location-stop {
  h1 {
    color: $header-background-color;
    font-size: 4rem;
  }
}
.location-des-text {
  h1 {
    color: $trails-text-color;
    font-size: 4rem;
    text-decoration: underline;
  }
  p {
    color: $trails-text-color;
    font-size: 3rem;
    margin-top: 2rem;
  }
}
.why-text {
  height: 50px;
  width: 100%;
  border: 1px solid $trails-text-color;
  color:#C3C0C0;
  outline: none;
  font-size: 2.5rem;
  padding-left: 2rem;
}
.why-text-heading {
  margin-top: 3rem;
}
.why-text-question {
  height: 150px;
  width: 100%;
  border: 1px solid $trails-text-color;
  color:#C3C0C0;
  outline: none;
  font-size: 2.5rem;
  margin-top: 3rem;
  padding-left: 2rem;
}
.people-flex {
  @extend .custom-flex;
  .people-text {
    p {
      font-size: 4rem;
      color: $trails-text-color;
    }
  }
  .people-box {
    width: 100%;
    margin-left: 1.5rem;
    border: 1px solid $trails-text-color;
    height: 60px;
    margin-top: 3rem;
  }
}
.yellow-border {
  border-top: 1px dashed $highlight-colour2 ;
}
.yellow-plus-icon {
  .fa-plus:before {
    content: "\f067";
    font-size: 3rem;
    color:$highlight-colour2;
}
}
.yellow-plus-icon {
  position: relative;
  top:-16px;
  text-align: center;
}
.more-text {
  p {
    color: $trails-text-color;
    font-size: 3rem;
    text-align: center;
  }
}
.review-btn {
  background-color: $header-background-color;
  padding: 8px 45px;
  color: #ffffff;
  text-align: center;
  font-size: 3rem;
  display: inline-block;
  &:hover {
    color: #ffffff;
  }
}
@media (min-width:768px)  {
  .trails-one h1 {font-size: 4rem}
  .trails-one-text h1 {font-size: 4rem;}
  //.header-logo h1 {font-size: 3rem;}
  .header-text a {
  //  padding: 10px 15px;
  }
  .your-text h1 { font-size: 3rem;}
  .get-start-btn {
    font-size: 2rem;
  }
  .get-start-btn { padding: 13px 53px;}
}
/*
.play-btn {
  background-color: $main-colour !important;
  border:none !important;
  padding: 6px 20px !important;
  font-size: 1.6rem !important;
  white-space: nowrap;
  margin-left: 5px !important;
}*/
.title-text-color {
  font-size: 22px;
  font-family: 'Sniglet', cursive;
}
.distance-text {
  font-size: 12px;
  flex-grow: 1;
}
.fa-play:before {
  font-size: 24px !important;
  line-height: 24px  !important;
 //content: "\f04b";
 //font-size: 13px;
}
.none-mobile-view {
  display: none !important;
}
.desktop-show {
  display: block !important;
}
.MuiMobileStepper-root {
  font-size: 17px !important;
  justify-content: center !important;
}
// .question-font-size {
//   font-size: 18px !important;
// }
.cus-flex-btn{
  display: flex;
  align-items: center;
}
.map-view-none {
  display: none !important;
}
.fa-globe-europe:before{
  content: "\f7a2";
  color: $main-colour;
  font-size: 32px;
}
.fa-th:before {
  content: "\f00a";
  color: $main-colour;
  font-size: 32px;
}
@media (min-width:280px) and (max-width:768px) {
  .left-right-space-new {
    padding-left: 0;
    padding-right: 0;
  }
  .grid-none {
    display: none !important;
  }
  .search-deign {
    width: 100% !important;
  }
  .map-view-none {
    display: block !important;
  }
  .cus-flex-btn {
    display: block !important;
  }
  .mobile-view-center-btn {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  .MuiTypography-h3 {
    font-size: 1.7rem !important;
  }
  .makeStyles-instructions-221 {
    margin: 10px !important;
  }
  .desktop-show {
    display: none !important;
  }
  .none-mobile-view {
    display: block !important;
  }
  .header-right {
    padding-left: 0;
    padding-right: 0;
  }
  .search-input {
    padding-left: 0;
    padding-right: 0;
  }
  // .search-deign {
  //   width: 100%;

  //   height: 5rem;
  // }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  .search-input {
    display: flex;
    justify-content: flex-start;
  }
  .header-logo img {
    width: 50px;
    margin: -50px -10px;
  }
  .header-logo h1 {
    //font-size: 1rem;  //ZH
//    padding-left: 1rem;
  }
  .header-text a {
   // font-size: 1rem;  //ZH
 //  padding: 10px 7px; ZH
  }
  .header-text {
   // margin-top: 6px;
  }
  .distance-text {
    font-size: 10px;
  }
  .space-top-box {
    padding-top: 2rem;
  }
  .search-input {
    padding-top: 2rem;
  }
  .margin-remove {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 1rem !important;
  }
}
.font-normal {
  font-weight: 400;
}
.MuiMobileStepper-root {
  background: $main-colour !important;
  color: #ffffff !important;
}
.map-left-right-space {
  padding-right: 45px !important;
}
.makeStyles-root-48 {
  font-size: 1.8rem !important;
}

@media (min-width:768px) and (max-width:1024px) {
  .left-right-space-new,.search-input,.header-right {
    padding-left: 0;
    padding-right: 0;
  }
  .desktop-show {
    display: none !important;
  }
  .none-mobile-view {
    display: block !important;
  }
  .margin-remove {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 1rem !important;
  }
  .MuiTypography-h3 {
    font-size: 2rem !important;
  }
}
.map-view-btn {
  width: 100%;
  background-color: $main-colour;
  padding: 10px 25px;
  outline: none;
  border: none !important;
  border-radius: 6px;
  font-size: 2rem;
  color: #ffffff !important;
}
button:focus {
  outline: none !important;
}
.gm-ui-hover-effect {
  top: -11px !important;
    right: 2px !important;
    width: 30px !important;
    height: 30px !important;
    img {
      width: 26px !important;
      height: 22px !important;
    }
}

.card {
  margin-bottom: 20px;
}

.imagefit{
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.maxWidhCointainer {
  max-width: 768px;
 }

 .missing-image {
  padding: 10px;
 }

 .cursor-pointer {
   cursor: pointer;
 }

.cursor-disable {
  cursor: not-allowed;
}
