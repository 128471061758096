.initiateQuestionButton {
    background-color: #E9E8DB;
    margin-top: 15px;
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 8px;

    Button {
        background-color: #0D9D93;
        color: white;
        font-size: 18px;
        line-height: 1;

        &:hover {
            background-color: #0D9D93;
            opacity: 0.5;
        }
    }

    img { height: 20px; width: auto !important; }
    h1 { font-size: 22px;}
}

img { max-height: 100% }

.trailImage {
    width: 100%;
    height: 270px;
    text-align: center;
}

.hunt_title {
    font-size: 22px !important;
    margin-top: 8px;
}

.mapIcon {
    height: 100%;
    width: 100%;
    margin-right: 10px;
    color: red;
}

.supplierInfo {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    margin-top: 15px;
}

.imagefit{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
